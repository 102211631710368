export const leftDateCount = {
    methods: {
        leftDateCount(date) {
            if (!date) {
                return '마감없음';
            } // date 없으면 '-' return하고 종료

            const currentDate = new Date();
            const inputDate = new Date(date);
            if (currentDate >= inputDate) { // 입력 date가 이미 지났을 경우
                return '마감';
            } else {
                const msDiff = inputDate.getTime() - currentDate.getTime();
                const hourDiff = Math.floor(msDiff / (1000 * 60 * 60));
                if (hourDiff >= 24) {
                    if (hourDiff % 24 == 0) {
                        return `${Math.floor(hourDiff/24)}일 남음`
                    } else {
                        return `${Math.floor(hourDiff/24)}일 ${hourDiff%24}시간 남음`
                    }
                } else {
                    return `${hourDiff%24}시간 남음`
                }
            }
        },
        activeDateCount(date) {
            if (!date) {
                return '';
            } // date 없으면 '-' return하고 종료

            const currentDate = new Date();
            const inputDate = new Date(date);
            if (currentDate < inputDate) { // 입력 date가 이미 지났을 경우
                return '';
            } else {
                const msDiff = currentDate.getTime() - inputDate.getTime();
                const hourDiff = Math.floor(msDiff / (1000 * 60 * 60));
                const minDiff = Math.floor(msDiff / (1000 * 60));
                if (hourDiff >= 24) {
                    if (hourDiff % 24 == 0) {
                        return `${Math.floor(hourDiff/24)}일전`
                    } else {
                        return `${Math.floor(hourDiff/24)}일전`
                    }
                } else if (hourDiff <= 1) {
                    return `${Math.floor(minDiff)}분전`
                } else if (hourDiff > 1 && hourDiff < 24) {
                    return `${Math.floor(hourDiff%24)}시간전`
                }
            }
        }
    },
}