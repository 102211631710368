<template>
    <div class="content_container product_view">
        <div class="inner">
            <div class="top_filter_wrap">
                <div class="filter_inner">
                    <div class="btn_filter" @click="showFilter = true">
                        <p>검색필터</p>
                        <div class="icon_wrap">
                            <img src="@/assets/images/common/icon_filter_b.svg" />
                        </div>
                    </div>
                    <div class="search_wrapper">
                        <input
                            type="text"
                            id="search"
                            placeholder="검색하려는 상품의 이름 혹은 해시태그를 입력하신 후 Enter를 눌러주세요"
                            :value="searchInput"
                            @input="searchInput = $event.target.value"
                            @keyup.enter="search"
                        />
                        <button type="button" class="btn_search" @click="search"></button>
                    </div>
                    <div class="fill_select">
                        <select v-model="sortBy">
                            <option :value="{ by: 'enrollment_date', option: 'down' }" selected>최신 순</option>
                            <option :value="{ by: 'enrollment_date', option: 'up' }">오래된 순</option>
                            <option :value="{ by: 'finish_date', option: 'down' }">마감일 순(내림차순)</option>
                            <option :value="{ by: 'finish_date', option: 'up' }">마감일 순(오름차순)</option>
                            <!-- <option>낮은 가격 순(오름차순)</option>
                        <option>높은 가격 순(내림차순)</option> -->
                        </select>
                    </div>
                </div>
            </div>

            <!--상세 검색필터 시작-->
            <div class="screen_filter" v-if="showFilter" @click="showFilter = false"></div>
            <div class="filter_detail_wrap">
                <button type="button" class="btn_close" v-if="showFilter" @click="showFilter = false">
                    <img src="@/assets/images/common/icon_close_w.svg" alt="close_w" />
                </button>
                <div class="filter_detail" v-show="showFilter">
                    <table class="fill filter_list">
                        <tr>
                            <th>
                                <p>카테고리</p>
                            </th>
                            <td>
                                <ul class="submenu fill_check">
                                    <li
                                        v-for="(item, i) in this.basicData.category.filter((el) => el.level == 1)"
                                        :key="i"
                                    >
                                        <label>
                                            <input
                                                type="checkbox"
                                                class="product_category"
                                                :value="item.category_pk"
                                                @click="submenuCheck($event, 'category', item.content)"
                                            />
                                            {{ item.content }}
                                        </label>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <p>예상마진</p>
                            </th>
                            <td>
                                <ul class="submenu fill_check">
                                    <li v-for="(item, i) in this.basicData.margin" :key="i">
                                        <label>
                                            <input
                                                type="checkbox"
                                                class="product_margin"
                                                :value="item.margin_pk"
                                                @click="submenuCheck($event, 'margin', item.content)"
                                            />
                                            {{ item.content }}
                                        </label>
                                    </li>
                                </ul>
                            </td>
                        </tr>

                        <tr>
                            <th>
                                <p>최소수량</p>
                            </th>
                            <td>
                                <ul class="submenu fill_check">
                                    <li v-for="(item, i) in this.basicData.moq" :key="i">
                                        <label>
                                            <input
                                                type="checkbox"
                                                class="product_moq"
                                                :value="item.moq_pk"
                                                @click="submenuCheck($event, 'moq', item.content)"
                                            />
                                            {{ item.content }}
                                        </label>
                                    </li>
                                </ul>
                            </td>
                        </tr>

                        <tr>
                            <th>
                                <p>제조기간</p>
                            </th>
                            <td>
                                <ul class="submenu fill_check">
                                    <li v-for="(item, i) in this.basicData.start" :key="i">
                                        <label>
                                            <input
                                                type="checkbox"
                                                class="product_start"
                                                :value="item.start_pk"
                                                @click="submenuCheck($event, 'start', item.supply_content)"
                                            />
                                            {{ item.supply_content }}
                                        </label>
                                    </li>
                                </ul>
                            </td>
                        </tr>

                        <tr>
                            <th>
                                <p>판매방식</p>
                            </th>
                            <td>
                                <ul class="submenu fill_check">
                                    <li v-for="(item, i) in this.basicData.way" :key="i">
                                        <label>
                                            <input
                                                type="checkbox"
                                                class="product_way"
                                                :value="item.way_pk"
                                                @click="submenuCheck($event, 'way', item.product_content)"
                                            />
                                            {{ item.product_content }}
                                        </label>
                                    </li>
                                </ul>
                            </td>
                        </tr>

                        <tr>
                            <th>
                                <p>판매지역</p>
                            </th>
                            <td class="area_table">
                                <ul class="submenu fill_check">
                                    <li class="category">
                                        <label> 국내 </label>
                                    </li>
                                    <div class="area">
                                        <li
                                            v-for="(item, i) in this.basicData.area.filter(
                                                (el) => el.division == '국내',
                                            )"
                                            :key="i"
                                        >
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    class="product_area"
                                                    :value="item.area_pk"
                                                    @click="submenuCheck($event, 'area', item.content)"
                                                />
                                                {{ item.content }}
                                            </label>
                                        </li>
                                    </div>
                                </ul>

                                <ul class="submenu fill_check">
                                    <li class="category li_border">
                                        <label> 전세계 </label>
                                    </li>
                                    <div class="area">
                                        <li
                                            v-for="(item, i) in this.basicData.area.filter(
                                                (el) => el.division == '전세계',
                                            )"
                                            :key="i"
                                        >
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    class="product_area"
                                                    :value="item.area_pk"
                                                    @click="submenuCheck($event, 'area', item.content)"
                                                />
                                                {{ item.content }}
                                            </label>
                                        </li>
                                    </div>
                                </ul>
                            </td>
                        </tr>

                        <tr>
                            <th>
                                <p>판매채널</p>
                            </th>
                            <td>
                                <ul class="submenu fill_check">
                                    <li v-for="(item, i) in this.basicData.channel" :key="i">
                                        <label>
                                            <input
                                                type="checkbox"
                                                class="product_channel"
                                                :value="item.channel_pk"
                                                @click="submenuCheck($event, 'channel', item.content)"
                                            />
                                            {{ item.content }}
                                        </label>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </table>
                    <div class="btn_wrapper">
                        <button type="button" class="common_button" @click="applyCheck">적용</button>
                        <button type="button" class="sub_button" @click="resetCheck">리셋</button>
                    </div>
                </div>
            </div>
            <!--상세 검색필터 끝-->

            <!--상품 시작-->
            <div class="div_wrapper">
                <div class="right_con">
                    <div class="searched_num">
                        <h1>
                            검색된 상품 <span>{{ this.searchList.length }}</span
                            >개
                        </h1>
                    </div>

                    <ul class="product_list">
                        <li
                            v-for="item in this.viewList"
                            :key="item.product_pk"
                            @click="this.$router.push(`/product/detail/${item.product_pk}`)"
                        >
                            <div class="top">
                                <p class="product_category">{{ item.category }}</p>
                                <div class="share click_on">
                                    <img src="@/assets/images/common/icon_share_border.svg" alt="클립 아이콘" />
                                    <img src="@/assets/images/common/icon_share_b.svg" alt="클립 아이콘" />
                                </div>
                            </div>
                            <div class="fill_image">
                                <div :id="'image_' + item.product_pk.toString()" class="product_image loading">
                                    <img
                                        :src="item.path + item.save_name"
                                        :alt="item.original_name"
                                        @load="onImgLoad($event)"
                                        @error="onErrorImage($event)"
                                    />
                                    <div v-if="!item.path && !item.save_name" class="no_image">
                                        <img src="@/assets/images/common/no_image.svg" />
                                        <!-- <img src="@/assets/images/common/no_image.svg" /> -->
                                    </div>
                                </div>
                            </div>

                            <!-- <p class="product_category">{{ item.category }}</p> -->
                            <p class="product_name">{{ item.name }}</p>
                            <p class="user_name">
                                <span class="label_provider">공급자</span>
                                {{ item.nick }}
                            </p>

                            <!--마우스 호버했을 때 btm_section 자리에 two_column_wrap 대신 hashtag_wrap 보이기-->
                            <div class="btm_section">
                                <div class="two_column_wrap">
                                    <div class="two_column">
                                        <p><span></span>소비자가</p>
                                        <h1 v-if="item.consumer_price_exposure_yn == '공개'">
                                            <i>&#8361;</i>{{ this.numberFormat(item.consumer_price) }}
                                        </h1>
                                        <h1
                                            v-else-if="
                                                item.consumer_price_exposure_yn == '회원공개' && this.access_token
                                            "
                                        >
                                            <i>&#8361;</i>{{ this.numberFormat(item.consumer_price) }}
                                        </h1>
                                        <h1 v-else>{{ item.consumer_price_exposure_yn }}</h1>
                                    </div>

                                    <div class="two_column">
                                        <p><span></span>공급가</p>
                                        <h1 v-if="item.supply_price_exposure_yn == '공개'">
                                            <i>&#8361;</i>{{ this.numberFormat(item.supply_price) }}
                                        </h1>
                                        <h1
                                            v-else-if="item.supply_price_exposure_yn == '회원공개' && this.access_token"
                                        >
                                            <i>&#8361;</i>{{ this.numberFormat(item.supply_price) }}
                                        </h1>
                                        <h1 v-else>{{ item.supply_price_exposure_yn }}</h1>
                                    </div>

                                    <div class="two_column">
                                        <p><span></span>예상마진</p>
                                        <h1>{{ item.margin }}</h1>
                                    </div>
                                </div>

                                <div class="hashtag_wrap">
                                    <span class="hashtag" v-for="(hashtag, i) in item.hashtag" :key="i">{{
                                        hashtag.hashtag
                                    }}</span>
                                    <span class="no_hashtag" v-if="item.hashtag.length == 0">해시태그 없음</span>
                                </div>
                            </div>

                            <div class="date_wrap">
                                <span class="active_info"> 2명 제안중 </span>
                                <span class="date_info">
                                    {{ this.leftDateCount(item.finish_date) }}
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!--상품 끝-->
        </div>

        <ToTop />
    </div>
</template>

<script>
import ToTop from "@/components/ToTop";

import { fetchData } from "@/utils/fetchData";
import { numberFormat } from "@/utils/numberFormat";
import { dateFormat } from "@/utils/dateFormat";
import { leftDateCount } from "@/utils/leftDateCount";
import { sortUp, sortDown } from "@/utils/sortItems.js";
import { mapState } from "vuex";

const MAX_PRODUCT_CARD = 20;

export default {
    components: {
        ToTop,
    },
    mixins: [fetchData, numberFormat, dateFormat, leftDateCount, sortUp, sortDown],
    computed: {
        ...mapState("access_token", ["access_token"]),
    },
    data() {
        return {
            showFilter: false,
            searchInput: "",
            searchList: [],
            viewList: [],
            hashTag: [],

            page: 0,

            // // 정렬 기준
            sortBy: {
                by: "enrollment_date",
                option: "down",
            },
            // 보낼 데이터
            area: [],
            moq: [],
            margin: [],
            category: [],
            channel: [],
            way: [],
            start: [],

            // 받아올 리스트 데이터
            list: [],

            // 받아올 기본 데이터
            basicData: {
                area: [],
                moq: [],
                margin: [],
                category: [],
                channel: [],
                way: [],
                start: [],
            },
        };
    },
    mounted() {
        this.fetchBasicData();
        this.loadMoreList();

        window.addEventListener("scroll", this.loadMoreList);
    },
    unmounted() {
        window.removeEventListener("scroll", this.loadMoreList);
    },
    watch: {
        // 검색 입력값 변경시 동작
        searchInput(search) {
            if (search == "") {
                // searchList 초기화
                this.page = 0;
                this.searchList = this.list;
                // 정렬 순으로
                if (this.sortBy.option === "up") {
                    this.searchList = this.sortByUp(this.searchList, this.sortBy.by);
                } else if (this.sortBy.option === "down") {
                    this.searchList = this.sortByDown(this.searchList, this.sortBy.by);
                }
            }
        },
        searchList() {
            const newViewList = this.searchList.slice(this.page * MAX_PRODUCT_CARD, (this.page + 1) * MAX_PRODUCT_CARD);

            this.viewList = newViewList;
            this.page++;
        },
        sortBy() {
            this.page = 0;
            if (this.sortBy.option === "up") {
                this.searchList = this.sortByUp(this.searchList, this.sortBy.by);
            } else if (this.sortBy.option === "down") {
                this.searchList = this.sortByDown(this.searchList, this.sortBy.by);
            } else {
                this.searchList = this.list;
            }
            this.searchList.forEach((item) => {
                item.isImageLoaded = false;
            });
        },
    },
    methods: {
        // 기본 메뉴 데이터 불러오기
        fetchBasicData() {
            this.fetchData("/getBasic?page=product", (data) => {
                this.basicData.area = data.area;
                this.basicData.moq = data.moq;
                this.basicData.margin = data.margin;
                this.basicData.category = data.category;
                this.basicData.channel = data.channel;
                this.basicData.way = data.way;
                this.basicData.start = data.start;

                this.list = data.list;

                this.page = 0;
                this.searchList = this.list;
                this.sortBy = {
                    by: "enrollment_date",
                    option: "down",
                };
            });
        },
        // 검색
        search() {
            this.page = 0;
            if (this.searchInput == "") {
                return;
            } else {
                this.viewList = [];
                this.searchList = this.list;
                const searchWords = this.searchInput.split(" ");

                searchWords.forEach((word) => {
                    this.searchList = this.searchList.filter(
                        (item) =>
                            item.name.toLowerCase().includes(word.toLowerCase()) || // 닉네임 일치여부
                            item.hashtag.some((el) => el.hashtag.toLowerCase().includes(word.toLowerCase())), // 해시태그 일치여부
                    );
                });
                this.sortBy = {
                    by: "enrollment_date",
                    option: "down",
                };
            }
        },
        // 서브메뉴 펼치기/접기
        toggleSubmenu(event) {
            event.currentTarget.classList.toggle("on");
        },
        // 서브메뉴 선택
        submenuCheck(event, which, content) {
            // 체크
            if (event.currentTarget.checked) {
                if (which == "category") {
                    this.category.push(event.currentTarget.value);
                } else if (which == "area") {
                    this.area.push(event.currentTarget.value);
                } else if (which == "channel") {
                    this.channel.push(event.currentTarget.value);
                } else if (which == "moq") {
                    this.moq.push(event.currentTarget.value);
                } else if (which == "start") {
                    this.start.push(event.currentTarget.value);
                } else if (which == "way") {
                    this.way.push(event.currentTarget.value);
                } else {
                    this.margin.push(event.currentTarget.value);
                }
                this.hashTag.push({
                    which: which,
                    value: event.currentTarget.value,
                    content: content,
                });
            } else {
                // 언체크
                if (which == "category") {
                    this.category.splice(this.category.indexOf(event.currentTarget.value), 1);
                } else if (which == "area") {
                    this.area.splice(this.area.indexOf(event.currentTarget.value), 1);
                } else if (which == "channel") {
                    this.channel.splice(this.channel.indexOf(event.currentTarget.value), 1);
                } else if (which == "moq") {
                    this.moq.splice(this.moq.indexOf(event.currentTarget.value), 1);
                } else if (which == "start") {
                    this.start.splice(this.start.indexOf(event.currentTarget.value), 1);
                } else if (which == "way") {
                    this.way.splice(this.way.indexOf(event.currentTarget.value), 1);
                } else {
                    this.margin.splice(this.margin.indexOf(event.currentTarget.value), 1);
                }

                let indexHash;
                this.hashTag.forEach((el, i) => {
                    if (el.content == content) {
                        indexHash = i;
                    }
                });
                this.hashTag.splice(indexHash, 1);
            }
        },
        // 조건 적용
        applyCheck() {
            this.fetchData(
                "/getProduct",
                (data) => {
                    this.list = [];
                    this.searchList = [];
                    this.viewList = [];
                    this.list = data.list;

                    this.page = 0;
                    this.searchList = this.list;
                    this.sortBy = {
                        by: "enrollment_date",
                        option: "down",
                    };
                },
                {
                    category: this.category.toString(),
                    area: this.area.toString(),
                    channel: this.channel.toString(),
                    moq: this.moq.toString(),
                    start: this.start.toString(),
                    margin: this.margin.toString(),
                    way: this.way.toString(),
                },
            );
            this.showFilter = false;
            this.searchInput = "";
        },
        // 해시태그 삭제
        deleteHash(item, i) {
            this.hashTag.splice(i, 1);

            if (item.which == "category") {
                this.category.splice(this.category.indexOf(item.value), 1);
                document.querySelectorAll(".product_category").forEach((el) => {
                    if (el.value == item.value) {
                        el.checked = false;
                    }
                });
            } else if (item.which == "area") {
                this.area.splice(this.area.indexOf(item.value), 1);
                document.querySelectorAll(".product_area").forEach((el) => {
                    if (el.value == item.value) {
                        el.checked = false;
                    }
                });
            } else if (item.which == "channel") {
                this.channel.splice(this.channel.indexOf(item.value), 1);
                document.querySelectorAll(".product_channel").forEach((el) => {
                    if (el.value == item.value) {
                        el.checked = false;
                    }
                });
            } else if (item.which == "moq") {
                this.moq.splice(this.moq.indexOf(item.value), 1);
                document.querySelectorAll(".product_moq").forEach((el) => {
                    if (el.value == item.value) {
                        el.checked = false;
                    }
                });
            } else if (item.which == "start") {
                this.start.splice(this.start.indexOf(item.value), 1);
                document.querySelectorAll(".product_moq").forEach((el) => {
                    if (el.value == item.value) {
                        el.checked = false;
                    }
                });
            } else if (item.which == "way") {
                this.way.splice(this.way.indexOf(item.value), 1);
                document.querySelectorAll(".product_way").forEach((el) => {
                    if (el.value == item.value) {
                        el.checked = false;
                    }
                });
            } else {
                this.margin.splice(this.margin.indexOf(item.value), 1);
                document.querySelectorAll(".product_margin").forEach((el) => {
                    if (el.value == item.value) {
                        el.checked = false;
                    }
                });
            }
        },
        // 해시태그 리셋
        resetCheck() {
            this.hashTag = [];
            this.area = [];
            this.moq = [];
            this.margin = [];
            this.way = [];
            this.start = [];
            this.category = [];
            this.channel = [];
            document.querySelectorAll(".product_category").forEach((el) => (el.checked = false));
            document.querySelectorAll(".product_area").forEach((el) => (el.checked = false));
            document.querySelectorAll(".product_channel").forEach((el) => (el.checked = false));
            document.querySelectorAll(".product_moq").forEach((el) => (el.checked = false));
            document.querySelectorAll(".product_start").forEach((el) => (el.checked = false));
            document.querySelectorAll(".product_way").forEach((el) => (el.checked = false));
            document.querySelectorAll(".product_margin").forEach((el) => (el.checked = false));

            document.querySelectorAll(".mainMenu.on").forEach((el) => el.classList.remove("on")); // 메뉴창 닫기
            this.applyCheck(); // 리셋후 다시 데이터 받음(전체 리스트)
            this.showFilter = false;
        },
        // 스크롤시 추가 리스트 로드
        loadMoreList() {
            if (Math.ceil(window.scrollY + window.innerHeight + 0.5) >= document.body.clientHeight) {
                const newViewList = this.searchList.slice(
                    this.page * MAX_PRODUCT_CARD,
                    (this.page + 1) * MAX_PRODUCT_CARD,
                );

                this.viewList = [...this.viewList, ...newViewList];
                this.page++;
            }
        },
        onImgLoad(event) {
            event.currentTarget.parentElement.classList.remove("loading");
        },
        onErrorImage(event) {
            event.currentTarget.parentElement.classList.remove("loading");
        },
    },
};
</script>

<style scoped src="@/assets/css/layout_product.css"></style>
